import { StateCreator } from "zustand";
import api from "../utils/api";
import { handleApiError } from "../utils/errorHandler";
import {
  setAuthCookies,
  clearAuthCookies,
  getAuthCookies,
} from "../utils/cookies";
import { jwtDecode } from "jwt-decode";
import { setUserInfoInLocalStorage } from "@/utils/localStorage";

// Define the shape of the authentication state
export interface ORGLoginAuthState {
  phoneNumber: string;
  countryCode: string;
  user: { token: string } | null;
  role: string | null;
  orderId: string | null; // Add orderId to track OTP order
  error: string | null;
  loading: boolean;
  updatePhoneNumber: (phoneNumber: string) => void;
  updateCountryCode: (countryCode: string) => void;
  ORGrequestOtp: () => Promise<void>;
  ORGresendOtpLogin: () => Promise<void>;
  ORGvalidateOtp: (otp: string) => Promise<boolean>;
  signOut: () => void;
}

// Helper function to decode the token and extract the role
const getRoleFromToken = (token: string): string | null => {
  try {
    const decoded: any = jwtDecode(token);
    return decoded.role || null;
  } catch (error) {
    console.error("Failed to decode token", error);
    return null;
  }
};

// Create the authentication slice
export const createORGAuthSlice: StateCreator<ORGLoginAuthState> = (
  set,
  get
) => {
  // Initialize state from cookies
  const authCookies = getAuthCookies();
  const initialRole = authCookies?.role || null;

  return {
    phoneNumber: "",
    countryCode: "",
    user: authCookies ? { token: authCookies.token } : null,
    role: initialRole, // Set role from cookies
    orderId: null, // Initialize orderId to null
    error: null,
    loading: false,

    updatePhoneNumber: (phoneNumber: string) => set({ phoneNumber }),

    updateCountryCode: (countryCode: string) => set({ countryCode }),

    // Request OTP and store orderId
    ORGrequestOtp: async () => {
      set({ loading: true, error: null });
      try {
        const { phoneNumber, countryCode } = get();
        const response = await api.post<{
          message: string;
          data: { orderId: string };
        }>("/auth/sendOTPtoOrgUser", {
          phoneNumber,
          countryCode,
        });
        const { orderId } = response.data.data;
        set({ orderId }); // Save orderId in the state
      } catch (error: any) {
        set({ error: handleApiError(error) });
        throw error;
      } finally {
        set({ loading: false });
      }
    },

    // Resend OTP request
    ORGresendOtpLogin: async () => {
      set({ loading: true, error: null });
      try {
        const { phoneNumber, countryCode } = get();
        const params = { countryCode, phoneNumber };
        await api.post("/auth/resendOTP", params);
      } catch (error: any) {
        set({ error: handleApiError(error) });
      } finally {
        set({ loading: false });
      }
    },

    // Validate OTP using orderId
    ORGvalidateOtp: async (otp: string) => {
      set({ loading: true, error: null });
      try {
        const { phoneNumber, countryCode, orderId } = get();

        if (!orderId) {
          throw new Error("Order ID is missing. Please request OTP again.");
        }

        const response = await api.post("/auth/verifyOTPForOrgUser", {
          otp,
          phoneNumber,
          countryCode,
          orderId, // Include orderId in the validation request
        });
        const { token, orgName, email, logo } = response.data;
        const role = getRoleFromToken(token);
        setAuthCookies({ token, role }); // Save token and role in cookies
        set({ user: { token }, role }); // Update state with user token and role
        setUserInfoInLocalStorage(orgName, email, logo);
        return true;
      } catch (error: any) {
        set({ error: handleApiError(error) });
        clearAuthCookies();
        set({ user: null, role: null });
        return false;
      } finally {
        set({ loading: false });
      }
    },

    // Sign out and clear user data
    signOut: () => {
      clearAuthCookies();
      set({ user: null, role: null, orderId: null }); // Reset orderId on logout
    },
  };
};
