import { Send } from "lucide-react";
import {
  ChatBubble,
  ChatBubbleAvatar,
  ChatBubbleMessage,
} from "@/components/ui/chat/chat-bubble";
import { ChatInput } from "@/components/ui/chat/chat-input";
import {
  ExpandableChat,
  ExpandableChatHeader,
  ExpandableChatBody,
  ExpandableChatFooter,
} from "@/components/ui/chat/expandable-chat";
import { ChatMessageList } from "@/components/ui/chat/chat-message-list";
import { Button } from "@/components/ui/button";
import { useState } from "react";

type Message = {
  type: "received" | "sent"; // Valid types for ChatBubble variant
  content: string;
};

export default function ChatSupport() {
  const [messages, setMessages] = useState<Message[]>([
    { type: "received", content: "Hello, how can I assist you today?" },
  ]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const API_URL = "http://34.30.103.128:8010/chat";
  const SESSION_ID = "your-session-id"; // Replace with actual session ID logic if needed.
  const MODEL = "gpt-4o-mini";

  const handleSend = async () => {
    if (!input.trim()) return;

    const newMessage: Message = { type: "sent", content: input };
    setMessages((prev) => [...prev, newMessage]);
    setInput(""); // Clear input field
    setIsLoading(true);

    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          question: input,
          session_id: SESSION_ID,
          model: MODEL,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        const receivedMessage: Message = {
          type: "received",
          content: data.answer,
        };
        setMessages((prev) => [...prev, receivedMessage]);
      } else {
        setMessages((prev) => [
          ...prev,
          { type: "received", content: "Something went wrong. Please try again." },
        ]);
      }
    } catch (error) {
      console.error("Error fetching API:", error);
      setMessages((prev) => [
        ...prev,
        { type: "received", content: "Failed to connect to the server. Please try again." },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ExpandableChat size="lg" position="bottom-right">
      <ExpandableChatHeader className="flex-col text-center justify-center">
        <h1 className="text-xl font-semibold">Chat with our AI ✨</h1>
        <p>Ask any question for our AI to answer</p>
      </ExpandableChatHeader>
      <ExpandableChatBody>
        <ChatMessageList>
          {messages.map((message, index) => (
            <ChatBubble key={index} variant={message.type}>
              <ChatBubbleAvatar fallback={message.type === "sent" ? "US" : "AI"} />
              <ChatBubbleMessage variant={message.type}>
                {message.content}
              </ChatBubbleMessage>
            </ChatBubble>
          ))}
          {isLoading && (
            <ChatBubble variant="received">
              <ChatBubbleAvatar fallback="AI" />
              <ChatBubbleMessage isLoading />
            </ChatBubble>
          )}
        </ChatMessageList>
      </ExpandableChatBody>
      <ExpandableChatFooter className="flex flex-row gap-2 justify-center">
        <ChatInput
          placeholder="Type your message here..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
        <Button
          type="submit"
          size="icon"
          onClick={handleSend}
          disabled={isLoading || !input.trim()}
        >
          <Send className="size-4" />
        </Button>
      </ExpandableChatFooter>
    </ExpandableChat>
  );
}
