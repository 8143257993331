import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface PageTransitionProps {
  children: React.ReactNode;
  pageKey: string; // Renamed from `key` to `pageKey`
}

const PageTransition: React.FC<PageTransitionProps> = ({ children, pageKey }) => {
  return (
    <AnimatePresence>
      <motion.div
        key={pageKey} // Use the renamed prop here
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
        style={{ position: 'absolute', width: '100%' }} // Ensures full-width coverage
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export default PageTransition;
